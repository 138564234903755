<template>
  <form class="flex flex-col space-y-4 form-json-form">
    <JsonForms
      ref="internalRef"
      :renderers="renderers"
      :schema="finalSchema"
      :uischema="finalUiSchema"
      :data="data || {}"
      :readonly="readonly"
      @change="onChange"
    />
  </form>
</template>
<script setup lang="ts">
import type { JsonSchema, UISchemaElement } from '@jsonforms/core'
import type { JsonFormsChangeEvent } from '@jsonforms/vue'
import { JsonForms } from '@jsonforms/vue'
import type { Nullable, Optional } from '@speckle/shared'
import { omit } from 'lodash-es'
import { useForm } from 'vee-validate'
import { renderers } from '~/lib/frontend/form/jsonRenderers'

type DataType = Record<string, unknown>

const emit = defineEmits<(e: 'change', val: JsonFormsChangeEvent) => void>()

const props = defineProps<{
  schema: JsonSchema
  uiSchema?: UISchemaElement
  readonly?: boolean
}>()

const { validate } = useForm()

const internalRef = ref<Nullable<{ jsonforms: { core: JsonFormsChangeEvent } }>>(null)
const data = defineModel<Record<string, unknown>>('data', { local: true })

const finalSchema = computed(() => {
  const base = props.schema
  return omit(base, ['$schema', '$id'])
})

const autoGeneratedUiSchema = computed(() => {
  const properties = Object.keys(props.schema.properties || {})
  return {
    type: 'VerticalLayout',
    elements: properties.map((p) => ({
      type: 'Control',
      scope: `#/properties/${p}`
    }))
  }
})
const finalUiSchema = computed(() => props.uiSchema || autoGeneratedUiSchema.value)

const onChange = async (e: JsonFormsChangeEvent) => {
  // console.log(JSON.parse(JSON.stringify(e)))
  data.value = e.data as DataType
  await validate({ mode: 'force' })
  emit('change', e)
}

const getFormState = (): Optional<JsonFormsChangeEvent> =>
  internalRef.value?.jsonforms.core
    ? ({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        data: internalRef.value.jsonforms.core.data,
        errors: internalRef.value.jsonforms.core.errors
      } as JsonFormsChangeEvent)
    : undefined

defineExpose({ getFormState })
</script>
<style lang="postcss">
.form-json-form {
  .vertical-layout {
    @apply space-y-4;
  }
}
</style>
